<template>
    <template v-if="page_status == 'show_form'">
            <div class="h-full flex flex-col max-w-xl mx-auto px-4 md:px-6">
                <!-- Tiny nav (modified) -->
                <div class="flex pt-6 -px-8">
                    <a @click.prevent="goToFilePicker">
                        <svg class="w-8 h-8 text-white" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor" d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zM142.1 273l135.5 135.5c9.4 9.4 24.6 9.4 33.9 0l17-17c9.4-9.4 9.4-24.6 0-33.9L226.9 256l101.6-101.6c9.4-9.4 9.4-24.6 0-33.9l-17-17c-9.4-9.4-24.6-9.4-33.9 0L142.1 239c-9.4 9.4-9.4 24.6 0 34z"></path>
                        </svg>
                    </a>
                    <div class="flex-1"></div>
                    <router-link :to="{name: 'matches' }" class="flex-initial">
                        <svg class="w-8 h-8 text-white" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path>
                        </svg>
                    </router-link>
                </div>

                <h1 class="text-white font-semibold text-2xl pt-4 pb-4 whitespace-pre-line" v-text="t('form.give_game_a_title')" />

                <form @submit.prevent="submitForm" enctype="multipart/form-data" class="flex-auto flex flex-col">
                    <div class="flex-auto">
                        <TextInput v-model:value="form.title" v-model:errors="errors.title" :alt-styling="true" :label="t('form.title_label')" :placeholder="t('form.title_placeholder')" />
                        <TextInput v-model:value="form.description" v-model:errors="errors.description" :alt-styling="true" :label="t('form.description_label')" :placeholder="t('form.description_placeholder')" class="pt-6" />
                        <SelectInput v-model:value="form.age" :options="match_types" v-model:errors="errors.age" :label="t('form.age_label')" class="pt-6" />
                    </div>
                    
                    <div class="flex-initial pb-10">
                        <span class="block w-full rounded-md shadow-sm mt-8">
                            <button type="submit" class="button w-full">
                                {{ t('form.upload') }}
                            </button>
                        </span>
                    </div>
                    
                </form>
            </div>

    </template>

    <template v-else-if="page_status == 'pick_file'">
        <PageWithButtonBelow :title="t('file_picker.choose_file')">
            <template v-slot:above-title>
                <TinyNavigation :prev="{name: 'match.create'}" :quit="{name: 'matches' }" />
            </template>
            <template v-slot:default>
                <div class="flex w-full flex-col items-center justify-center bg-grey-lighter">
                    <label class="w-full md:w-1/2 lg:w-1/3 flex flex-col items-center px-4 py-6 bg-white text-gray-900 rounded-lg shadow-lg tracking-wide uppercase border border-scorelitorange cursor-pointer hover:bg-gray-200 hover:text-black transition duration-200">
                        <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                        </svg>
                        <span v-if="form.file_name" class="mt-2 font-medium text-xs">{{ form.file_name }}</span>
                        <span v-else class="mt-2 font-medium text-xs">{{ t('file_picker.select_file') }}</span>
                        <input 
                            type="file" 
                            ref="file_upload"
                            name="file" 
                            @change="filesChange" 
                            accept="video/mp4,video/x-m4v,video/*"
                            class="hidden"
                        >
                    </label>                        
                </div>
                <template v-if="errors.file.length !== 0">
                    <p v-for="error in errors.file" :key="error" class="mt-2 font-medium text-sm text-red-600">{{ error }}</p>
                </template>
            </template>

            <template v-slot:button>
            <span @click.prevent="goToForm" class="block w-full rounded-md shadow-sm">
                    <button type="button" class="button w-full">
                        {{ t('file_picker.next_step') }}
                    </button>
                </span>
            </template>
        </PageWithButtonBelow>
    </template>

    <template v-else-if="page_status == 'loading'">
        <div>
            <div class="max-w-xl mx-auto px-4 md:px-6 pt-8">
                <h1 class="text-white font-semibold text-2xl pt-4 pb-4">{{ t('loading.is_uploading') }}</h1>
            
                <div class="relative py-32">
                    <div class="flex mb-2 items-center justify-between">
                        <div>
                            
                        </div>
                        <div class="text-right">
                            <span class="text-xs font-semibold inline-block text-white">
                                {{ status.progress }}%
                            </span>
                        </div>
                    </div>
                    <div class="overflow-hidden h-4 mb-4 text-xs flex rounded bg-gray-700">
                        <div :style="'width:' + status.progress + '%'" class="progress-bar shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center transition duration-200"></div>
                    </div>
                </div>

                <div class="mt-8">
                    <span @click.prevent="cancelRequest" class="block w-full rounded-md shadow-sm">
                        <button type="button" class="button w-full">
                            {{ t('loading.cancel') }}
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </template>

</template>

<script>
import PageWithButtonBelow from "@/wrappers/PageWithButtonBelow";
import TextInput from "@/components/forms/TextInput";
import SelectInput from "@/components/forms/SelectInput";
import TinyNavigation from "@/components/TinyNavigation";
import { storeMatch } from "@/helpers/api/MatchHelper";
import axios from 'axios';
import { getMatchTypesBySport } from "@/helpers/constants/match_types"
import useI18n from "@/modules/globalI18n";

export default {
    name: "MatchCreate2",
    components: { TextInput, SelectInput, TinyNavigation, PageWithButtonBelow },
    setup() {
        return useI18n({
            viewPrefix: "MatchCreate2"
        });
    },
    data(){
        return {
            form:{
                title: "",
                description: "",
                age: "",
                file: undefined,
                file_name: "",
            },
            errors: {
                title: [],
                description: [],
                age: [],
                file: [],
            },
            status: {
                loading: false,
                file_picking: true,
                progress: 0,
            },
            axios: {
                cancelToken: null,
                cource: null,
            },
            match_types: [],
        };
    },
    created(){
        // set match_types
        const user = this.$store.getters['getUser'];
        this.match_types = getMatchTypesBySport(user.sport_id);

        this.setDefaultAge(user);

        this.setupAxiosCancelToken()
    },
    methods:{
        setDefaultAge(user){
            const { age } = user;

            if (this.match_types.some(mt => mt.key == age)) {
                this.form.age = age;
            } else {
                const ages = this.match_types.map(mt => parseInt(mt.key));
                const minAge = Math.min(...ages);
                const maxAge = Math.max(...ages);

                if (age < minAge) {
                    this.form.age = minAge.toString();
                } else if (age > maxAge) {
                    this.form.age = maxAge.toString();
                }    
            }
        },
        submitForm(){
            this.status.progress = 0;
            this.status.loading = true;

            const onUploadProgress = (progressEvent) => { 
                this.status.progress = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            };

            storeMatch(this.form, onUploadProgress, this.axios.source.token)
                .then(data =>{
                    this.$router.push({name: 'match.create.3', params: {id: data.match.id}})
                })
                .catch(err => {
                    this.errors = err.errors;
                })
                .finally(()=>{
                    this.status.loading = false;
                    this.status.progress = 0;
                });
        },
        setupAxiosCancelToken(){
            this.axios.cancelToken = axios.CancelToken;
            this.axios.source = this.axios.cancelToken.source();
        },
        cancelRequest(){
            this.axios.source.cancel();

            this.setupAxiosCancelToken()
        },
        filesChange(){
            this.form.file = this.$refs.file_upload.files[0];

            this.form.file_name = this.$refs.file_upload.files[0].name;
        },
        goToForm(){
            if(!this.form.file){
                this.errors.file = [];
                this.errors.file.push(this.t('file_picker.select_file_before_continue'));
                return;
            }
            this.status.file_picking = false;
        },
        goToFilePicker(){
            this.status.file_picking = true;
        },
    },
    computed:{
        page_status(){
            if (this.status.loading){
                return 'loading';
            }
            if(this.status.file_picking){
                return 'pick_file';
            }
            return 'show_form';
        },
    },
}
</script>

<style scoped>
.progress-bar {
    background-color: #EF4136;
    background: #EF4136;
    background: linear-gradient(to right, #F7941D 0, #EF4136 50%, #F7941D 100%);
    background-position: left center;
    background-size: 200%;
}
</style>